import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle, css } from 'styled-components';

import { ThemeProvider } from '../../../contexts';

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    *, *::before, *::after {
      box-sizing: border-box;
    }
    html {
      line-height: 1.15;
      -webkit-text-size-adjust: 100%;
    }
    body {
      margin: 0;
    }
    main {
      display: block;
    }
    h1 {
      font-size: 2em;
      margin: 0.67em 0;
    }
    hr {
      box-sizing: content-box;
      height: 0;
      overflow: visible;
    }
    pre {
      font-family: monospace, monospace;
      font-size: 1em;
    }
    a {
      background-color: transparent;
    }
    abbr[title] {
      border-bottom: none;
      text-decoration: underline;
    }
    b,
    strong {
      font-weight: bolder;
    }
    code,
    kbd,
    samp {
      font-family: monospace, monospace;
      font-size: 1em;
    }
    small {
      font-size: 80%;
    }
    sub,
    sup {
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }
    sub {
      bottom: -0.25em;
    }
    sup {
      top: -0.5em;
    }
    img {
      border-style: none;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
      font-family: inherit;
      font-size: 100%;
      line-height: 1.15;
      margin: 0;
    }
    button,
    input {
      overflow: visible;
    }
    button,
    select {
      text-transform: none;
    }
    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
      -webkit-appearance: button;
    }
    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }
    button:-moz-focusring,
    [type="button"]:-moz-focusring,
    [type="reset"]:-moz-focusring,
    [type="submit"]:-moz-focusring {
      outline: 1px dotted ButtonText;
    }
    fieldset {
      padding: 0.35em 0.75em 0.625em;
    }
    legend {
      box-sizing: border-box;
      color: inherit;
      display: table;
      max-width: 100%;
      padding: 0;
      white-space: normal;
    }
    progress {
      vertical-align: baseline;
    }
    textarea {
      overflow: auto;
    }
    [type="checkbox"],
    [type="radio"] {
      box-sizing: border-box;
      padding: 0;
    }
    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      height: auto;
    }
    [type="search"] {
      -webkit-appearance: textfield;
      outline-offset: -2px;
    }
    [type="search"]::-webkit-search-decoration {
      -webkit-appearance: none;
    }
    ::-webkit-file-upload-button {
      -webkit-appearance: button;
      font: inherit;
    }
    details {
      display: block;
    }
    summary {
      display: list-item;
    }
    template {
      display: none;
    }
    [hidden] {
      display: none;
    }
    body {
      width: 100% !important;
      height: 100%;
      margin: 0;
      background-color: ${theme.colors.bg100};
      color: ${theme.colors.contrast100};
      -webkit-text-size-adjust: none;
      font-family: 'Montserrat', sans-serif;
    }

    h1 {
      font-family: 'Bebas Neue', sans-serif;
      font-size: 40px;
    }

    h2 {
      font-family: 'Bebas Neue', sans-serif;
      font-size: 24px;
    }

    h3 {
      font-family: 'Bebas Neue', sans-serif;
      font-size: 18px;
    }

    h4 {
      font-family: 'Bebas Neue', sans-serif;
      font-size: 13px;
    }

    h5 {
      font-family: 'Bebas Neue', sans-serif;
      font-size: 10px;
    }

    h6 {
      font-family: 'Bebas Neue', sans-serif;
      font-size: 9px;
    }

    footer p {
      font-size: 14px;
    }

    footer p > a {
      color: ${theme.colors.white80};
      text-decoration: none;
      font-weight: 700;
    }
  `}}
`;

function Layout({ children }) {
  return (
    <ThemeProvider>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Para todos los visitantes de México, Visits les da itinerarios en destinos" />
          <meta name="keywords" content="Visits,Turismo,México,Destinos Turísticos" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat&display=swap" rel="stylesheet" />
          <link rel="alternate" hrefLang="es" href="https://www.visits.mx/" />
          <link rel="alternate" hrefLang="en" href="https://www.visits.mx/en/" />
          <link rel="alternate" hrefLang="x-default" href="https://www.visits.mx/" />
        </Helmet>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  );
}

Layout.defaultProps = {
  children: null,
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
